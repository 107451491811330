<kt-portlet autocomplete="off">
	<kt-portlet-header [icon]="'fas fa fa-print'">
		<ng-container ktPortletTitle class="ps-0">
			<h3 class="kt-portlet__head-title py-1 me-1">
				<strong>{{ dataDlg.title }}</strong>
			</h3>
		</ng-container>
	</kt-portlet-header>
	<kt-portlet-body [modalScroll]="true">
		<div class="container p-2">
			<form [formGroup]="formGroup">
				<div class="row">
					<div class="col-lg-8">
						<ng-select-simple #tbTipoContenedor [virtualScroll]="false" [items]="dataFormato$" formControlName="formato" [required]="true" label="Formato" (ngModelChange)="onChangeTipoFomato($event)">
						</ng-select-simple>
					</div>
					<div class="col-lg-4" style="align-content: center; margin-top: 20px">
						<mat-checkbox #checkisPdf formControlName="isPdf">Convertir a PDF </mat-checkbox>
					</div>
				</div>
				<ng-container *ngIf="isEmail">
					<div class="row px-0">
						<div class="col-lg-12 my-1">
							<label>Destinatario (Para) <span [ngClass]="{ isValid: isValid('validDestinatario'), isInvalid: isInvalid('validDestinatario') }">*</span> </label>
							<mat-form-field style="width: 100%" appearance="outline">
								<!-- <mat-label>Correo</mat-label> -->
								<mat-chip-grid #chipGridDestinatario formControlName="validDestinatario">
									<mat-chip-row class="mat-chip" *ngFor="let keyword of $any(formGroup.get('destinatarios')).controls; let i = index" (removed)="removeCorreo(i, 'destinatarios')">
										{{ keyword.value }}
										<button matChipRemove>
											<mat-icon style="color: black">cancel</mat-icon>
										</button>
									</mat-chip-row>
									<input matInput placeholder="Nuevo destinatario" [matChipInputFor]="chipGridDestinatario" (matChipInputTokenEnd)="addClipRow($event, 'destinatarios')" />
								</mat-chip-grid>
								<mat-error
									style="margin-left: -18px; font-family: 'Soho Gothic Pro' !important; font-size: 11.375px"
									*ngIf="formGroup.get('validDestinatario')?.invalid && (formGroup.get('validDestinatario')?.touched || formGroup.get('validDestinatario')?.touched)">
									Debe agregar al menos un correo
								</mat-error>
							</mat-form-field>
						</div>
					</div>
					<div class="row px-0">
						<div class="col-lg-12" [ngClass]="{ isValidMargin: isValid('validDestinatario'), isInvalidMargin: isInvalid('validDestinatario') }">
							<label>Con copia (CC)</label>
							<mat-form-field style="width: 100%" appearance="outline">
								<!-- <mat-label>Correo</mat-label> -->
								<mat-chip-grid #chipGridCopia formArrayName="conCopiaA">
									<mat-chip-row class="mat-chip" *ngFor="let keyword of $any(formGroup.get('conCopiaA')).controls; let i = index" (removed)="removeCorreo(i, 'conCopiaA')">
										{{ keyword.value }}
										<button matChipRemove aria-label="'remove ' + keyword">
											<mat-icon style="color: black">cancel</mat-icon>
										</button>
									</mat-chip-row>
									<input matInput placeholder="Nueva copia" [matChipInputFor]="chipGridCopia" (matChipInputTokenEnd)="addClipRow($event, 'conCopiaA')" />
								</mat-chip-grid>
							</mat-form-field>
						</div>
					</div>
				</ng-container>
			</form>
		</div>
	</kt-portlet-body>
	<kt-portlet-footer class="py-3">
		<div class="row">
			<div class="col-lg-7 d-flex align-items-center">
				<span class="text-danger">* Obligatorio</span>
			</div>
			<div class="col-lg-5 d-flex justify-content-end my-auto">
				<button #aceptar type="button" (click)="imprimir()" class="btn btn-success me-3" style="width: 80px">{{ labelButton }}</button>
			</div>
		</div>
	</kt-portlet-footer>
</kt-portlet>
