/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApplicationRef, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ColDef, GridApi, IRowNode, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { SacCotizacionService } from 'app/services/acuerdos-comerciales/sacCotizacion.service';
import { format } from 'date-fns';
import { Observable, Subscription, firstValueFrom, of, tap } from 'rxjs';
import { ConfigButtonAction, ConfigButtonAgGrid, ConfigButtonHeader, EnumButtonType } from 'ngx-sumax-erp-component';
import { SacCotizacion } from '~models/acuerdos-comerciales/SacCotizacion';
import { SciAccion } from '~models/config';
import { SacCotizacionTipoOperador } from '~models/maestros-sync/maestros/SacCotizacionTipoOperador';
import { IconAgGridRenderComponent } from '~shared/components/icon-ag-grid-render/icon-ag-grid-render.component';
import { SolicitudCotizacionDialogComponent } from './solicitud-cotizacion-dialog/solicitud-cotizacion-dialog.component';
import { CellEstadoRenderComponent } from '~shared/components/cell-estado-render/cell-estado-render.component';
import { ConfigFilter } from './filtro-cotizacion-por-criterio-busqueda/filtro-cotizacion-por-criterio-busqueda.component';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DateUtil } from '~shared/utils/DateUtil';
import { EnumReferenceRoutingFilterControl } from '~models/Util/EnumReferenceControlRoutingFilterControl';
import { EnumAccion } from '~shared/enums/EnumAccion';
import { environment } from 'environments/environment';
import { SearchSacCotizacion } from '~models/acuerdos-comerciales/custom/SearchSacCotizacion';
import { MessageUtilService } from '~shared/services/message-util.service';
import { StoreService } from '~shared/services/store.service';
import { EnumPermissionDefault } from '~shared/enums/EnumPermission';
import { NotificationService } from 'app/services/notification.service';
import { DialogData } from '~shared/interface/DialogData';
import { VerCotiResumenComponent } from '../../common/ver-coti-resumen/ver-coti-resumen.component';
import { SdoPrinterService } from 'app/services/doc-oper/sdoPrinter.service';
import { PrintTipoDocumentoDialogComponent } from './print-tipo-documento-dialog/print-tipo-documento-dialog.component';
import { FiltroPorReferenciaService } from './filtro-por-referencia.service';
import { hideLoading, showLoading } from '~shared/utils/LoadingUtil';
import { EnumNumeroCotizacion } from '~shared/enums/EnumNumeroCotizacion';
import { SdoTipoDocumentoService } from 'app/services/doc-oper/sdoTipoDocumento';
import { SdoTipoDocumento } from '~models/doc-oper/SdoTipoDocumento';
import { CotizacionService } from '../cotizacion.service';
import { SdoPrinterCustom } from '~models/doc-oper/SdoPrinterCustom';
import { Store } from '@ngrx/store';
import { AppState } from '@core/store';
import { currentUser } from '@core/store/selectors/auth.selectors';
import { AperturaOrdenDialogComponent } from './apertura-orden-dialog/apertura-orden-dialog.component';
import { SacCotizacionTipoOperadorService } from 'app/services/acuerdos-comerciales/sacCotizacionTipoOperador.service';
import { EnumTipoOperador } from '~shared/enums/EnumTipoOperador';
import { SacCotizacionEstadoService } from 'app/services/acuerdos-comerciales/sacCotizacionEstado.service';
import { EnumEstadoCotizacion } from '~shared/enums/EnumEstadoCotizacion';

interface ValueGetterParamsCustom extends ValueGetterParams {
	data: SacCotizacion;
}

export const constHeadersListaCotizacion = {
	_NUMERO: 'Número',
	_FECHA: 'Fecha',
	_ESTADO: 'Estado',
	_TIPOOPERACION: 'Tipo operac.',
	_VIATRANSPORTE: 'Via transp.',
	_TIPOFLETE: 'Tipo de flete',
	_OPERADOR: 'Operador',
	_INCOTERM: 'Incoterm',
	_CLIENTE: 'Cliente',
	_ORIGEN: 'Origen',
	_DESTINO: 'Destino',
	_VALIDODESTE: 'Válido desde',
	_VALIDOHASTA: 'Válido hasta',
	_TRANSITO: 'Tránsito',
	_FRECUENCIA: 'Frecuencia',
	_EJECUTIVOCOMERCIAL: 'Ejecutivo comercial',
};

@Component({
	selector: 'app-lista-cotizacion',
	templateUrl: './lista-cotizacion.component.html',
	styleUrls: ['./lista-cotizacion.component.scss'],
})
export class ListaCotizacionComponent implements OnInit, OnDestroy {
	frameworkComponents = {
		cellEstadoRenderComponent: CellEstadoRenderComponent,
		iconAgGridRenderComponent: IconAgGridRenderComponent,
	};

	formGroup!: UntypedFormGroup;
	configButtonsAction: ConfigButtonAction = {};
	columnDefs: ColDef[] = [];
	accesos: SciAccion[] = [];
	codigoActions: (string | null | undefined)[] = [];
	gridApi?: GridApi;
	newtabs: Array<Window> = [];

	listaSacCotizaciones: SacCotizacion[] = [];

	subscription$ = new Subscription();
	filterConfig!: ConfigFilter;
	isNotificacion = true;

	rowData$: Observable<SacCotizacion[]> = of([]);
	permissions: string[] = [];
	private readonly _agGridActionsHeader: ConfigButtonHeader = {
		disabled: false,
		submenu: [
			{
				actionCode: 'ACC-ELIMINAR-MULTIPLE',
				label: 'Eliminar',
				icon: 'fa fa-trash',
				iconClass: 'text-danger',
				hide: (): boolean => !this.permissions.includes(EnumAccion.Eliminar),
			},
		],
	};
	private readonly agGridActions: ConfigButtonAgGrid[] = [
		{
			actionCode: 'ACC-VER-RESUMEN',
			tooltipOption: { label: 'Resumen', classList: 'bg-primary' },
			iconOption: { classList: 'text-primary', icon: 'fas fa-eye' },
			hide: (): boolean => !this.permissions.includes(EnumAccion.Resumen),
		},
		{
			actionCode: 'ACC-FLETE-CARGOS',
			tooltipOption: { label: 'Flete/Cargos', classList: 'bg-success' },
			iconOption: { classList: 'text-success', icon: 'far fa-money-bill-alt' },
			hide: (): boolean => !this.permissions.includes(EnumAccion.FleteCargos),
		},
		{
			actionCode: EnumAccion.ImprimirCotizacion,
			tooltipOption: { label: 'Emitir', classList: 'bg-primary' },
			iconOption: { classList: 'text-primary', icon: 'fa fa-print' },
			hide: (): boolean => !this.permissions.includes(EnumAccion.FleteCargos),
		},
		{
			actionCode: '',
			tooltipOption: { label: 'Más acciones' },
			iconOption: { icon: 'fa fa-ellipsis-v', classList: 'text-black-50' },
			submenu: [
				{
					actionCode: EnumAccion.GenerarRo,
					icon: 'fas flaticon2-website',
					iconClass: 'text-success',
					label: 'Generar Orden',
					hide: (): boolean => !this.permissions.includes(EnumAccion.GenerarRo),
				},
				{
					actionCode: EnumButtonType.TYPE_DELETE,
					icon: 'fa fa-trash',
					iconClass: 'text-danger',
					label: 'Eliminar',
					hide: (): boolean => !this.permissions.includes(EnumPermissionDefault.Eliminar),
				},
			],
		},
	];

	constructor(
		private readonly sacCotizacionService: SacCotizacionService,
		private readonly dialog: MatDialog,
		private readonly _routerService: Router,
		private readonly _activatedRoute: ActivatedRoute,
		private readonly messageUtilService: MessageUtilService,
		private readonly _matDialog: MatDialog,
		private readonly _changeDetectorRef: ChangeDetectorRef,
		private readonly _applicationRef: ApplicationRef,
		private readonly _storeService: StoreService,
		private readonly _notificationService: NotificationService,
		private readonly _sdoPrinterService: SdoPrinterService,
		private readonly _sdoTipoDocumentoService: SdoTipoDocumentoService,
		private readonly _filtroPorReferenciaService: FiltroPorReferenciaService,
		private readonly _cotizacionService: CotizacionService,
		private readonly _sacCotizacionTipoOperadorService: SacCotizacionTipoOperadorService,
		private appStore: Store<AppState>,
		private readonly _cotizacionEstadoService: SacCotizacionEstadoService
	) {}

	ngOnInit(): void {
		this.filterByNotification();
		this._initAgGrid();
		this._selectStore();
		this._updateListCotizacion();

		this._activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
			const dateForFilter = `01/${params.get('month') || ''}/${params.get('year') || ''}`;
			const lastDay = params.get('year') ? DateUtil.getLastDayFromMonth(dateForFilter) : '';

			this.filterConfig = {
				filtro: params.get('filtro') as EnumReferenceRoutingFilterControl,
				value: Number(params.get('value')),
				firstDate: dateForFilter,
				lastDate: lastDay ?? '',
			};
		});
	}

	filterByNotification(): void {
		this._activatedRoute.params.subscribe({
			next: (e) => {
				if (e.nroCotizacion === undefined || e.nroCotizacion === null) {
					this.isNotificacion = true;
				} else {
					this.isNotificacion = false;
					setTimeout(() => {
						const anio = String(e.nroCotizacion).split('-')[0];
						const nroCotizacion = +String(e.nroCotizacion).split('-')[1];
						const searchCotizacio = new SearchSacCotizacion();
						searchCotizacio.ano = +anio;
						searchCotizacio.nroCotizacion = String(nroCotizacion);
						searchCotizacio.tipoFiltro = 'porReferencia';
						searchCotizacio.tipoReferencia = EnumNumeroCotizacion.NROCOTI.code;
						this.onFilterForReferencia(searchCotizacio as SearchSacCotizacion);
					}, 1000);
				}
			},
		});
	}

	private _initAgGrid(): void {
		this.columnDefs = [
			{
				headerName: constHeadersListaCotizacion._NUMERO,
				field: 'nroCotizacion',
				width: 155,
				resizable: false,
				sortable: true,
				pinned: 'left',
				suppressMenu: true,
				lockPosition: true,
			},
			{
				headerName: constHeadersListaCotizacion._FECHA,
				field: 'fchCotizacion',
				width: 120,
				resizable: false,
				cellClass: ['d-flex justify-content-end'],
				valueFormatter(params: ValueFormatterParams): string {
					return params?.value ? format(new Date(params?.value), 'dd/MM/yyyy') : '';
				},
			},
			{
				headerName: constHeadersListaCotizacion._ESTADO,
				field: 'sacTipoEstadoCotizacion',
				cellStyle: { 'text-align': 'center', 'padding-left': 2, 'padding-right': 2 },
				cellRenderer: 'cellEstadoRenderComponent',
				pinned: 'left',
				resizable: false,
				lockPosition: true,
				width: 140,
			},
			{
				headerName: constHeadersListaCotizacion._TIPOOPERACION,
				field: 'tbTipoManifiesto.nombre',
				width: 140,
			},
			{
				headerName: constHeadersListaCotizacion._VIATRANSPORTE,
				field: 'tbViaTransporte.nombre',
				width: 140,
			},
			{
				headerName: constHeadersListaCotizacion._TIPOFLETE,
				field: 'tbTipoMovimientoContenedor.nombre',
				width: 140,
			},
			{
				headerName: constHeadersListaCotizacion._OPERADOR,
				field: 'sacCotizacionTipoOperadores',
				valueGetter: (params: ValueGetterParamsCustom): SacCotizacionTipoOperador[] => params.data.sacCotizacionTipoOperadores ?? [],
				cellRenderer: 'iconAgGridRenderComponent',
				cellStyle: { 'white-space': 'pre-line' },
				width: 140,
			},
			{
				headerName: constHeadersListaCotizacion._INCOTERM,
				field: 'tbIncoterm.codigo',
				width: 140,
			},
			{
				headerName: constHeadersListaCotizacion._CLIENTE,
				field: 'tbCliente.tbEntidad.razonSocial',
				width: 140,
			},
			{
				headerName: constHeadersListaCotizacion._ORIGEN,
				field: 'tbUbicacionComercialEmbarque',
				valueGetter: (params: ValueGetterParamsCustom): string => params.data.tbUbicacionComercialEmbarque?.nombre + ' , ' + params.data.tbUbicacionComercialEmbarque?.tbPais?.nombre,
				width: 140,
			},
			{
				headerName: constHeadersListaCotizacion._DESTINO,
				field: 'tbUbicacionComercialLlegada',
				valueGetter: (params: ValueGetterParamsCustom): string => params.data.tbUbicacionComercialLlegada?.nombre + ' , ' + params.data.tbUbicacionComercialLlegada?.tbPais?.nombre,
				width: 140,
			},
			{
				headerName: constHeadersListaCotizacion._VALIDODESTE,
				field: 'fchVigenciaDesde',
				valueFormatter(params: ValueFormatterParams): string {
					return params?.value ? format(new Date(params?.value), 'dd/MM/yyyy') : '';
				},
				width: 140,
			},
			{
				headerName: constHeadersListaCotizacion._VALIDOHASTA,
				field: 'fchVigenciaHasta',
				valueFormatter(params: ValueFormatterParams): string {
					return params?.value ? format(new Date(params?.value), 'dd/MM/yyyy') : '';
				},
				width: 140,
			},
			{
				headerName: constHeadersListaCotizacion._TRANSITO,
				field: 'tiempoTransito',
				width: 140,
			},
			{
				headerName: constHeadersListaCotizacion._FRECUENCIA,
				field: 'frecuenciaSalida',
				width: 140,
			},
			{
				headerName: constHeadersListaCotizacion._EJECUTIVOCOMERCIAL,
				field: 'tbEjecutivoVendedor',
				valueGetter: (params: ValueGetterParamsCustom): string =>
					params.data?.tbEjecutivoVendedor
						? params.data.tbEjecutivoVendedor?.tbPersona?.nombre + ' ' + params.data.tbEjecutivoVendedor?.tbPersona?.apellido1 + ' ' + params.data.tbEjecutivoVendedor?.tbPersona?.apellido2
						: '',
				width: 140,
			},
		];
	}

	private _selectStore(): void {
		this.subscription$.add(
			this._storeService.currentActions$.subscribe(([actions, defaultAction]) => {
				this.permissions = actions;
				this.configButtonsAction = {
					header: { ...this._agGridActionsHeader },
					agGrid: {
						actions: [defaultAction[0], ...this.agGridActions],
					},
				};
			})
		);
	}

	private async _updateListCotizacion(): Promise<void> {
		//Actualizar solo estado
		this._notificationService.getUpdatedEstadoCotizacion().subscribe((dataEstado) => {
			const payloadEstado = dataEstado['payload'];
			const cotiEstado = JSON.parse(payloadEstado?.toString()) as SacCotizacion;
			this._updateGridApi(cotiEstado, null);
		});
		//Actualizar estado y tipo operador
		this._notificationService.getUpdatedCotizacionList().subscribe(([dataEstado, dataTipoOperador]) => {
			const payloadEstado = dataEstado['payload'];
			const payloadTipoOperador = dataTipoOperador['payload'];

			const cotiEstado = JSON.parse(payloadEstado?.toString()) as SacCotizacion;
			const cotiTipoOperador = JSON.parse(payloadTipoOperador?.toString()) as SacCotizacion;
			this._updateGridApi(cotiEstado, cotiTipoOperador);
		});
	}

	//Actualiza la tabla
	private _updateGridApi(cotiEstado: SacCotizacion, cotiTipoOperador: SacCotizacion | null): void {
		this.gridApi?.forEachNodeAfterFilterAndSort((rowNode: IRowNode) => {
			const rowCotizacion = rowNode?.data as SacCotizacion;
			if (rowCotizacion.idCotizacion === cotiEstado?.idCotizacion) {
				if (rowCotizacion.idTipoEstadoCotizacion !== cotiEstado.idTipoEstadoCotizacion) {
					rowCotizacion.idTipoEstadoCotizacion = cotiEstado.idTipoEstadoCotizacion;
					rowCotizacion.sacTipoEstadoCotizacion = cotiEstado.sacTipoEstadoCotizacion;
				}
			}
			if (rowCotizacion.idCotizacion === cotiTipoOperador?.idCotizacion) {
				if (cotiTipoOperador?.sacCotizacionTipoOperadores != null) {
					rowCotizacion.sacCotizacionTipoOperadores = [...cotiTipoOperador.sacCotizacionTipoOperadores];
				}
			}
			rowNode?.setData(rowCotizacion);
		});
	}

	// private _updateListCotizacion(): void {
	// 	this._notificationService?.getUpdatedEstadoCotizacion()?.subscribe((data) => {
	// 		const payload = data['payload'];
	// 		const cotizacion = JSON.parse(payload?.toString()) as SacCotizacion;
	// 		this.gridApi?.forEachNodeAfterFilterAndSort((rowNode: IRowNode) => {
	// 			const rowCotizacion = rowNode?.data as SacCotizacion;
	// 			console.log('cotizacion', cotizacion);
	// 			if (rowCotizacion.idCotizacion === cotizacion?.idCotizacion) {
	// 				if (rowCotizacion.idTipoEstadoCotizacion !== cotizacion.idTipoEstadoCotizacion) {
	// 					rowCotizacion.idTipoEstadoCotizacion = cotizacion.idTipoEstadoCotizacion;
	// 					rowCotizacion.sacTipoEstadoCotizacion = cotizacion.sacTipoEstadoCotizacion;
	// 				}
	// 				if (cotizacion.sacCotizacionTipoOperadores != null) {
	// 					rowCotizacion.sacCotizacionTipoOperadores = [...cotizacion.sacCotizacionTipoOperadores];
	// 				}
	// 				rowNode?.setData(rowCotizacion);
	// 			}
	// 		});
	// 	});
	// }

	async onButtonAction(typeAction: string, actionCode: string, resource?: any): Promise<void> {
		const cotizacion: SacCotizacion = resource?.data;
		if (typeAction == 'ActionAgGrid') {
			switch (actionCode) {
				case 'ACC-FLETE-CARGOS':
					this._openFleteCargos(cotizacion);
					break;
				case 'ACC-VER-RESUMEN':
					this._actionAgGridTypeResum(cotizacion);
					break;
				case String(EnumAccion.ImprimirCotizacion):
					this.actionAgGridTypeImprimirCotizacion(cotizacion);
					break;
				case String(EnumAccion.GenerarRo):
					this.actionAgGridTypeAperturaOrden(cotizacion);
					break;
			}
		}
	}

	// eslint-disable-next-line @typescript-eslint/naming-convention
	async _actionAgGridTypeResum(sacCotizacion: SacCotizacion): Promise<void> {
		const data = new DialogData<number>();
		data.data = sacCotizacion.idCotizacion;

		this._matDialog.open(VerCotiResumenComponent, {
			width: '1050px',
			panelClass: 'custom-dialog-container',
			height: '90vh',
			maxHeight: '90vh',
			data,
		});
	}

	onAddRow(): void {
		this.dialog
			.open(SolicitudCotizacionDialogComponent, {
				width: '1200px',
				disableClose: true,
			})
			.afterClosed()
			.subscribe(({ refreshList, cotizacion }) => {
				if (refreshList) {
					this.onFilterSearchCotizaciones(this.changeFilterRo);
					this._openFleteCargos(cotizacion);
				}
			});
	}

	async onEditRow(cotizacion: SacCotizacion): Promise<void> {
		const respEstados = await firstValueFrom(this._cotizacionEstadoService.findAllByIdCotizacionCustom(cotizacion.idCotizacion as number));
		let isEstadoEnviado = false;
		if (respEstados) {
			isEstadoEnviado = respEstados.some((estado) => estado.codigo === EnumEstadoCotizacion.ENVIADA);
		}
		const serializeUrl =
			'#' +
			this._routerService.serializeUrl(
				this._routerService.createUrlTree([`/${environment.PATH_BASE}/cotizacion/registro`], {
					queryParams: { idCotizacion: cotizacion.idCotizacion, isEstadoEnviado: isEstadoEnviado },
					relativeTo: this._activatedRoute,
				})
			);
		const newTab = window.open(serializeUrl, '_blank');
		if (newTab) this.openTab(newTab, true);
	}

	async onDeleteRow(gridApi: GridApi, rowNodes: SacCotizacion[]): Promise<void> {
		const cotizacion: SacCotizacion = rowNodes[0];
		this.messageUtilService.getMessageQuestion(`¿Desea eliminar la cotización N° ${cotizacion.nroCotizacion}?`, 'Esta acción no se puede deshacer').then((res) => {
			if (res.value) {
				this.subscription$.add(
					this.sacCotizacionService.deleteCotizacion(cotizacion).subscribe((e) => {
						this.onFilterSearchCotizaciones(this.changeFilterRo);
						this.messageUtilService.getAlertSuccessBasic(e.mensaje);
					})
				);
			}
		});
	}

	async onFilterSearchCotizaciones(search: SearchSacCotizacion): Promise<void> {
		this.gridApi?.showLoadingOverlay();
		this.sacCotizacionService.findListaCotizaciones(search).subscribe((data: SacCotizacion[]) => {
			this.gridApi?.setGridOption('rowData', data);
			this._autosizeGrid();
		});
	}

	onGridReady(gridApi: GridApi): void {
		this.gridApi = gridApi;
		this.gridApi.showLoadingOverlay();
	}

	private _autosizeGrid(): void {
		this.gridApi?.redrawRows();
	}

	// Filter - Events
	changeFilterRo!: SearchSacCotizacion;
	onFilterForReferencia(changeFilter: SearchSacCotizacion): void {
		this.changeFilterRo = changeFilter;
		this.onFilterSearchCotizaciones(changeFilter);
	}

	onFilterForCriterio(searchCriterioBusqueda: SearchSacCotizacion): void {
		if (this.isNotificacion) {
			this.changeFilterRo = searchCriterioBusqueda;
			this.onFilterSearchCotizaciones(searchCriterioBusqueda);
		} else {
			this.isNotificacion = true;
		}
	}

	// Flete/Cargos
	private _openFleteCargos(cotizacion: SacCotizacion): void {
		const url = 'flete-cargo';
		const { idCotizacion, sacTipoEstadoCotizacion } = cotizacion;

		const serializeUrl =
			'#' +
			this._routerService.serializeUrl(
				this._routerService.createUrlTree([`/${environment.PATH_BASE}/cotizacion/${url}`], {
					queryParams: {
						idCotizacion,
						data: this.configPageHeaderTab({
							color: sacTipoEstadoCotizacion?.color,
							text: sacTipoEstadoCotizacion?.nombre,
						}),
					},
					relativeTo: this._activatedRoute,
				})
			);
		const newTab = window.open(serializeUrl, `_${url}_${idCotizacion}`);
		if (newTab) this.openTab(newTab);
	}

	openTab(newtab: Window, closeClickPestania = false): void {
		this.newtabs = this.newtabs.filter((tab: { name: string }) => tab.name !== '');
		if (this.newtabs.length > 0) {
			let isValid = false;
			this.newtabs.forEach((element) => {
				if (element == newtab) {
					isValid = true;
				}
			});
			if (!isValid) {
				this.newtabs.push(newtab);
			}
		} else {
			this.newtabs.push(newtab);
		}
		this.newtabs.forEach((window: Window) => {
			let isWindowClose = false;
			if (!window) return;
			if (!closeClickPestania) {
				window.addEventListener('unload', () => {
					if (isWindowClose) {
						this.onFilterSearchCotizaciones(this.changeFilterRo);
						const index = this.newtabs.findIndex((tab: Window) => tab === window);
						if (index !== -1) this.newtabs.splice(index, 1);
						this._applicationRef.tick();
						this._changeDetectorRef.markForCheck();
					}
					isWindowClose = true;
				});
			} else {
				window.addEventListener('beforeunload', () => {
					this.onFilterSearchCotizaciones(this.changeFilterRo);
					const index = this.newtabs.findIndex((tab: Window) => tab === window);
					if (index !== -1) this.newtabs.splice(index, 1);
					this._applicationRef.tick();
					this._changeDetectorRef.markForCheck();
				});
			}
		});
	}

	configPageHeaderTab(configTag?: any): string {
		return JSON.stringify({
			title: 'Flete cargos',
			subTitle: 'Modificar',
			action: EnumAccion.Editar,
			visibleFavorite: false,
			configTag,
			configNgSelect: {
				selectOffice: {
					visible: true,
				},
			},
		});
	}

	async actionAgGridTypeImprimirCotizacion(cotizacion: SacCotizacion): Promise<void> {
		const coti = { ...cotizacion };
		const resp = await firstValueFrom(this._sdoTipoDocumentoService.findAllByCodigoSistema(coti));
		if (resp && resp?.length > 0) {
			if (resp.length > 1) {
				const dataResp = resp.filter((f) => f.idTipoDocumento != null && f.tbTipoFormato != null);
				//abrir modal
				if (dataResp) {
					const data = new DialogData<SdoTipoDocumento[]>();
					data.data = dataResp;
					data.title = 'Cotización';
					data.object = coti;
					this._matDialog.open(PrintTipoDocumentoDialogComponent, {
						width: '500px',
						data,
					});
				} else {
					this.messageUtilService.getMessageInfoSmall('no existe plantilla asociada');
				}
			} else if (resp.length === 1) {
				try {
					showLoading('Generando documento...');
					const data: SdoPrinterCustom = {
						object: coti,
						idTipoDocumento: resp[0].idTipoDocumento,
						isPdf: false,
						idUsuario: this.getUserId(),
					};
					this.subscription$.add(
						this._sdoPrinterService.generateFomatos([data]).subscribe({
							next: (e) => {
								e.forEach((element) => {
									const contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
									this._filtroPorReferenciaService.downloadBase64File(contentType, element.response, element.sdoTipoDocumentoDto?.plantilla);
								});
								hideLoading();
							},
							error: () => hideLoading(),
						})
					);
				} catch {}
			} else {
				this.messageUtilService.getMessageInfoSmall('no existe plantilla asociada');
			}
		} else {
			this.messageUtilService.getMessageInfoSmall('no existe documentos configurados');
		}
	}

	async actionAgGridTypeAperturaOrden(cotizacion: SacCotizacion): Promise<void> {
		if (!cotizacion) {
			return;
		}
		if (cotizacion.idCotizacion) {
			const listTipoOperadores: SacCotizacionTipoOperador[] = await firstValueFrom(this._sacCotizacionTipoOperadorService.findAllByIdCotizacion(cotizacion.idCotizacion));

			if (listTipoOperadores) {
				if (listTipoOperadores.length > 1) {
					const data = new DialogData<SacCotizacion>();
					data.data = cotizacion;
					data.title = 'Cotización';
					data.subTitle = 'Generar Orden';
					data.object = listTipoOperadores;
					this.dialog.open(AperturaOrdenDialogComponent, {
						width: '600px',
						data,
					});
					// .afterClosed()
					// .subscribe((cotizacion) => {
					// 	// console.log(cotizacion);
					// });
				} else if (listTipoOperadores.length == 1) {
					if (listTipoOperadores[0].tbTipoOperador?.codigo == EnumTipoOperador.AGTE_CARGA) {
						await this._cotizacionService.aperturarScaRouting(cotizacion.idCotizacion, this.getUserId());
					}
				}
			}
		}
	}

	getUserId(): number {
		let idUsuario = 0;
		this.subscription$.add(
			this.appStore
				.select(currentUser)
				.pipe(
					tap((res) => {
						idUsuario = res?.id as number;
					})
				)
				.subscribe()
		);
		return idUsuario;
	}

	ngOnDestroy(): void {
		this.subscription$.unsubscribe();
	}
}
